import type { AxiosResponse } from 'axios';

export const triggerDownloadFile = (response: AxiosResponse, defaultFileName: string) => {
  const href = URL.createObjectURL(response.data);
  const regExpFilename = /filename="(?<filename>.*)"/;
  const filename = regExpFilename.exec(response.headers['content-disposition'])?.groups?.filename ?? defaultFileName;

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
